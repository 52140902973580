import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import Paginate from 'vuejs-paginate-next';
import store from './store/store';
import KeySearchConfigurator from './KeySearchConfigurator.vue';

const el = document.getElementById('vue_key_search_configurator');
if (el) {
    const app = createApp(KeySearchConfigurator);
    const vfm = createVfm();
    initSentry(app);
    app.use(store);
    app.use(vfm);
    app.component('paginate', Paginate);
    window.vue_key_search_configurator = app.mount('#vue_key_search_configurator');
}
