<template>
  <div class="description-list">
    <RowFields />
    <RowFormat />
    <RowRelevant />
    <RowBase />
    <RowDemo />
  </div>
</template>

<script>

import RowFields from "./RowFields.vue";
import RowFormat from "./RowFormat.vue";
import RowRelevant from "./RowRelevant.vue";
import RowDemo from "./RowDemo.vue";
import RowBase from "./RowBase.vue";

export default {
  name: "Description",
  components: {
    RowBase,
    RowDemo,
    RowRelevant,
    RowFormat,
    RowFields
  },
}
</script>

<style scoped>
.description-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: black;
}
</style>