<template>
  <div class="banner" />
</template>

<script>
export default {
  name: 'Banner',
};
</script>

<style scoped>
.banner {
  top: -5px;
  height: 5px;
  border-radius: 0 0 3px 3px;
  background: linear-gradient(90deg,var(--color-company-search-block-prime),var(--color-company-search-block-secondary));
}
</style>
