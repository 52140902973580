import { Tree, NodeValue } from 'tree';
import api from '../../../api/api';

export default {
    namespaced: true,

        state: {
          /**
           * @var {Tree}
           */
            tree: {},

            searchString: '',

          /**
           * @var {Tree}
           */
            searchTree: {},

            tooltipVisible: false,

    },

    getters: {
        tooltipVisible(state) {
            return state.tooltipVisible;
        },

        getGeoTree: state => {
            return state.tree;
        },
        getGeoSearchTree: state => {
            return state.searchTree;
        },
        isSearchMode: state => {
            return (state.searchString.length >= 3);
        },
        emptySearchResult: state => {
            return state.searchTree.isEmpty();
        },

      // Возращает true, false. Если дерево полностью выбрано
        treeFullSelected(state) {
            if (!state.tree.root) { // Перед инициализацией дерева недоступно значение root
                return false;
            }
            return state.tree.root.value.isChosen && !state.tree.root.value.isPartiallyChosen;
        },

        // Есть ли выбранные города
        hasSelectedCities(state) {
            // когда дерево не прогрузилось возвращаем true, так как по умолчанию выбрана вся Россия
            if (!state.tree.root) {
                return true;
            }
            return state.tree.root.value.isPartiallyChosen || state.tree.root.value.isChosen;
        },

      // выбранные пункты, отформатированные для отправки запроса на сервер
        selectedGeoUnits(state,getters){
            if (getters.treeFullSelected) {
                return [];
            }
            let chosenCities = state.tree.getChosenNodesFromLevel(4);
            chosenCities = chosenCities.map(region => {
                return {
                    id: region.value.obj.id,
                    name: region.value.obj.name,
                    action: 'search',
                }
            })
            return chosenCities;
        },
    },

    mutations: {
        setGeoTree: (state, payload) => {
            const geoUnits = payload.geoUnits;

            let territories = payload.territories;

            territories = new Set(
                territories
                    .map((territory) => {
                        territory.id = Number(territory.id);
                        return territory;
                    })
                    .filter((territory) => territory.id !== 9),
            );

        state.tree = new Tree(4);

        let regions = geoUnits.filter(region => region.level === 2);

        regions = regions.sort((a, b) => {
            if (a.id === 35 || a.id === 32) {
                return -1;
            }
            if (b.id === 35 || b.id === 32) {
                return 1;
            }
            return 0
          });

        regions = regions.map(region => {
            if (region.id === 32 || region.id === 35) {
                region.main = true;
            }
            return region;
        })

        let cities = geoUnits.sort((a,b) => a.important < b.important ? 1 : -1);

        cities = cities.sort((a, b) => {
            // Остальные населенные пункты опускаются
            if (a.is_other_locality) {
                return 1;
            }
            if (b.is_other_locality) {
                return -1;
            }
            // Москва и Питер поднимается
            if (a.id === 585 || a.id === 493 || a.id === 461) {
                return -1;
            }
            if (b.id === 585 || b.id === 493 || a.id === 461) {
                return 1;
            }
            if (a.important === 1 || b.important === 1) {
                return 0;
            }
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
          })

        cities = cities.map((city) => {
            if (city.id === 585 || city.id === 461 || city.id === 493) {
                city.important = 0;
                city.extra = 1;
            }
            return city;
        })

        cities = new Set(cities.filter(city => city.level === 3));

        // добавление 1-ого уровня - страна
        let nodeValue = new NodeValue(
            {id: 1, name: 'Россия'},
            1,
            true,
            false,
            false,
            true,
        );
        state.tree.addNodeByParentId(nodeValue, 0);

        territories.forEach(territory => {
            let nodeValue = new NodeValue(
                territory,
                2,
                true,
                false,
                false,
                true,
            );
        state.tree.addNodeByParentId(nodeValue, 1);
        });

        regions.forEach((region) => {
            let nodeValue = new NodeValue(region , 3);
            if (region.id === 35) {
                nodeValue = new NodeValue(region , 3, true);
            }

            state.tree.addNodeByParentId(nodeValue, region.id_territory);
          });

        cities.forEach(city => {
            let nodeValue = new NodeValue(city, 4);
            state.tree.addNodeByParentId(nodeValue, city.region);
          });
        },
        expandGeoNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        chooseGeoNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },
        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },
        resetSearch(state) {
            state.searchString = '';
        },
        expandGeoSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        syncSearchTree: state => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseOrCancelTree: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        chooseOrCancelSearchTree(state, choice) {
            state.searchTree.selectOrDropAllNodes(choice);
        },

        setTooltipVisible(state, value) {
            state.tooltipVisible = value;
        },
    },

    actions: {
        async setGeoTree({commit}) {
            const geoUnits = await api.getCitiesList();
            const territories = await api.getTerritories();
            commit('setGeoTree', {geoUnits, territories});
            commit('chooseGeoNode', {level: 1, id: 1});
        },
        chooseGeoSearchNode: ({commit}, nodeDesc) => {
            commit('chooseGeoNode', nodeDesc);
            commit('syncSearchTree');
        },
        updateSearch: ({commit,getters}, searchString) => {
            commit('setSearchString', searchString);
            if (getters['isSearchMode']) {
                commit('runSearch');
            }
        },

        chooseGeoNode({commit}, nodeDesc) {
            commit('chooseGeoNode', nodeDesc);
            commit('runSearch');
        },
    }
}
