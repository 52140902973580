<template>
  <div>
    <NotificationComponent
      v-if="tooShortQuery"
      flipper-position="without-flipper"
    >
      Укажите ключевое слово или ИНН в строке поиска
    </NotificationComponent>

    <NotificationComponent
      v-else
      flipper-position="without-flipper"
    >
      <span v-if="exactMatchSearch">
        Если допустимо отбрасывать окончания слов, выключите "Точное соответствие"
      </span>
      <span v-else>
        Если необходимо буквальное соответствие заданным словам, включите "Точное соответствие"
      </span>
      <ExactMatchCheckbox
        class="searchMatchCheckbox"
        :checked="exactMatchSearch"
        @change="changeSearchMatch"
      />
    </NotificationComponent>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ExactMatchCheckbox from '../ExactMatchCheckbox/ExactMatchCheckbox.vue';
import NotificationComponent from '../../../../../../common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'KeySearchNotificationList',
  components: {
    NotificationComponent,
    ExactMatchCheckbox,
  },

  computed: {
    ...mapState([
      'exactMatchSearch',
    ]),

    ...mapGetters([
      'tooShortQuery',
    ]),
  },

  methods: {
    ...mapActions([
      'changeSearchMatch',
    ]),
  },
};
</script>

<style scoped>
.searchMatchCheckbox {
  margin-top: 10px;
}
</style>
