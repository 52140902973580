<template>
  <div>
    <div>
      <div class="new_company_summary_fixed_wrapper">
        <Card
          :total-cost="cost"
          :total-count="countOfCompanies"
          :visible-discount="discountPercentage"
          :relative-discount="(discountPercentage / 100)"
          @downloadButtonClick="handlePaymentClick"
          @demoClick="demoClick"
        >
          <template #notifications>
            <KeySearchNotificationList />
          </template>
        </Card>

        <div
          v-show="isPreload"
          class="preloaderWrapper"
        />
        <span
          v-show="isPreload"
          class="preloaderText"
        >
          Производим расчет...
        </span>
      </div>
    </div>
    <div
      class="hintsBlock"
      @click="hintClick"
    >
      <NotificationComponent
        v-if="showSectionHint"
        flipper-position="flipper-left"
      >
        <p>
          Возможно, большее число компаний найдется в следующих рубриках
          <a
            href="/"
            target="_blank"
          >Базы предприятий</a>:
        </p>
        <div class="linkBlock">
          <p
            v-for="(section, index) in truncatedSectionList"
            :key="index"
            :title="section.name"
          >
            <a
              :href="'/?sId=' + section.id"
              target="_blank"
              class="pseudoCheckbox"
            />
            <a
              :href="'/?sId=' + section.id"
              target="_blank"
              class="name"
            >{{ section.name }}</a>
          </p>
        </div>
        <div
          v-if="remainderCountOfSection > 0"
          class="remainderCountOfSectionBlock"
        >
          <a
            href="/?action=focusOnSearchInput"
            target="_blank"
          >и еще [{{ remainderCountOfSection }}
            {{ formattedNameOfRubric(remainderCountOfSection) }}]
          </a>
        </div>
        <p>Обращаем внимание, что в Базе предприятий доступен поиск по рубрикам.</p>
      </NotificationComponent>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { pluralize } from 'numeralize-ru';
import Card from '../../../../common_components/Card/Card.vue';
import NotificationComponent from '../../../../common_components/NotificationComponent/NotificationComponent.vue';
import KeySearchNotificationList from './components/KeySearchNotificationList/KeySearchNotificationList.vue';

export default {
  name: 'Summary',

  components: {
    Card,
    NotificationComponent,
    KeySearchNotificationList,
  },

  computed: {
    ...mapState([
      'countOfCompanies',
      'cost',
      'discountPercentage',
      'sectionsList',
      'isPreload',
    ]),

    ...mapState('payPopup', [
      'customerIsAuthorized',
    ]),

    showSectionHint() {
      return this.findSections && this.isFindCompanies;
    },

    findSections() {
      return this.sectionsList.length;
    },

    isFindCompanies() {
      return this.countOfCompanies !== 0;
    },

    truncatedSectionList() {
      return this.sectionsList.filter((element, index) => index < 5);
    },

    remainderCountOfSection() {
      return this.sectionsList.length - 5;
    },

    allowOpenPaymentModal() {
      return (this.cost > 0);
    },
  },

  methods: {
    ...mapMutations('payPopup', [
      'setVisiblePayPopup',
    ]),

    ...mapActions('payPopup', [
      'getAuthStatus',
      'getUserData',
    ]),

    demoClick() {
      window.vue_demo_popup.$store.dispatch('openPopup');
    },

    formattedNameOfRubric(count) {
      return pluralize(count, 'рубрика', 'рубрики', 'рубрик');
    },

    hintClick() {
      try {
        window.yaCounter23743225.reachGoal('notification-search');
        console.log('notification-search');
      } catch (e) {
        console.groupCollapsed('ERROR Yandex-metric:');
        console.log(e);
        console.groupEnd();
      }
    },

    async handlePaymentClick() {
      if (this.allowOpenPaymentModal) {
        try {
          window.yaCounter23743225.reachGoal('order_poisk');
        } catch (e) {
          console.groupCollapsed('ERROR Yandex-metric:');
          console.log(e);
          console.groupEnd();
        }
        await this.getAuthStatus();

        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setVisiblePayPopup(true);
      } else {
        echo(
          'Ничего не найдено, измените параметры поиска чтобы перейти на оплату',
          6000,
          'warning',
        );
      }
    },
  },

};
</script>

<style scoped lang="less">
.preloaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: .4;
  background-color: #999;
}

.preloaderText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 101;
  padding: 15px 20px;
  border-radius: 5px;
  text-align: center;
  background-color: #fff;
  font-size: 14px;
  font-style: italic;
}

.hintsBlock {
  width: 365px;
  margin-top: 100px;

  * {
    font-size: 13px;
    font-style: normal;
  }

  a {
    color: #353535;
  }

  .linkBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0 10px 5px;

    p {
      padding: 4px 0;
      display: flex;
      align-items: center;

      a.name {
        text-decoration: none;
        border-bottom: 1px dashed #353535;
        margin-left: 7px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 290px;
      }

      .pseudoCheckbox {
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 1px #999 solid;
        border-radius: 4px;
      }
    }

    p:hover {
      .pseudoCheckbox {
        background-color: var(--color-button-prime-hover);
        box-shadow: 0 0 6px 2px #999;
      }
    }
  }
}

.remainderCountOfSectionBlock {
  display: flex;

  a {
    color: #29b915;
    border-bottom: 1px dashed #353535;
    font-weight: bold;
    cursor: pointer;
    margin: -3px 15px 10px 40px;
    font-style: italic;
    text-decoration: none;
  }
}

.innOgrnCard {
  p:first-child {
    margin-bottom: 10px;
  }
}
</style>
