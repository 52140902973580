<template>
  <div class="key_search_container">
    <section class="mainConfigurator eb-active-tab-continuation-block-key-search">
      <div class="key_search__top_row">
        <span class="key_search__attention__text">
          Введите ключевые слова в строку поиска и нажмите на кнопку "Поиск". <br>
        </span>
        <div class="key_search__text__buttons">
          <p class="key_search__faq__text">
            Сервис позволяет найти компании,
            в названии и на сайтах которых присутствуют ключевые слова. <br>
          </p>
        </div>
      </div>

      <GeoTree v-if="visibleGeoTree" />

      <div class="wrapper-row">
        <CitiesList />
        <FAQButton @click="showExample" />
      </div>
      <SearchBar />
    </section>

    <Banner />

    <section
      id="companyListAndBasket"
      class="companyListAndBasket"
    >
      <CompanyList />
      <Summary />
      <PayPopup
        v-if="visiblePayPopup"
        :visible-pay-popup="visiblePayPopup"
        :email="email"
        :order-id="orderId"
        :client-id="clientId"
        :customer-is-authorized="customerIsAuthorized"
        :client-balance="clientBalance"
        :cost="cost"
        :count-companies="countOfCompanies"
        :user-discount="0"
        :initial-tab="initialTab"
        :show-order-preloader="showOrderPreloader"
        payment-target="company_search_by_key"
        service="company_search_by_key"
        @close-popup="closePayPopup"
        @input-email="changeEmail"
        @create-order="createOrder"
        @create-bill="createBill"
        @init-login="initLogin"
      >
        <template #description>
          <Description />
        </template>
      </PayPopup>
    </section>
    <ModalsContainer />
  </div>
</template>

<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { ModalsContainer } from 'vue-final-modal';
import PayPopup from '@/common_components/PayPopup/PayPopup.vue';
import Description from '@/key_search/KeySearchConfigurator/components/Description/Description.vue';
import Summary from './components/Summary/Summary.vue';
import CompanyList from './components/CompanyList/CompanyList.vue';
import SearchBar from './components/SearchBar/SearchBar.vue';
import CitiesList from './components/CitiesList/CitiesList.vue';
import Banner from './components/Banner/Banner.vue';
import GeoTree from './components/GeoTree/GeoTree.vue';
import FAQButton from './components/FAQButton/FAQButton.vue';

export default {
  name: 'KeySearchConfigurator',

  components: {
    ModalsContainer,
    Description,
    PayPopup,
    FAQButton,
    GeoTree,
    Banner,
    SearchBar,
    CompanyList,
    Summary,
    CitiesList,
  },

  computed: {
    ...mapState([
      'visibleGeoTree',
      'cost',
      'countOfCompanies',
    ]),

    ...mapState('payPopup', [
      'visiblePayPopup',
      'email',
      'orderId',
      'clientId',
      'clientBalance',
      'customerIsAuthorized',
      'initialTab',
      'showOrderPreloader',
    ]),

    ...mapGetters('geoTree', ['selectedGeoUnits']),
    ...mapGetters([
      'searchBarIsEmpty',
    ]),
  },

  async created() {
    await this.setGeoTree();
    await this.setCities(this.selectedGeoUnits);
  },

  methods: {
    ...mapMutations([
      'setCities',
      'setExampleSearchBarState',
    ]),

    ...mapMutations('payPopup', [
      'setVisiblePayPopup',
      'setEmail',
      'setInitialTab',
      'setShowOrderPreloader',
    ]),

    ...mapActions('geoTree', [
      'setGeoTree',
    ]),

    ...mapActions(['searchCompany']),
    ...mapActions('payPopup', [
      'createOrder',
      'createBill',
      'getAuthStatus',
      'getUserData',
    ]),

    addEventForBalanceBlock() {
      document.body.addEventListener('open-balance-block', async () => {
        await this.getAuthStatus();
        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialTab('BalanceForm');
        await this.setVisiblePayPopup(true);
        this.setInitialTab('');
      });
    },

    initLogin() {
      window.open_balance_block = true;
      this.addEventForBalanceBlock();
      this.setVisiblePayPopup(false);
      document.dispatchEvent(new Event('openAuthWrapper'));
    },

    changeEmail(email) {
      this.setEmail(email);
    },

    showExample() {
      if (!this.searchBarIsEmpty) {
        if (!confirm('Текущий поиск будет сброшен. Подтвердите.')) return;
      }
      this.setExampleSearchBarState();
      this.searchCompany();
    },

    closePayPopup() {
      this.setVisiblePayPopup(false);
    },
  },
};
</script>

<style scoped lang="less">
.key_search_container {
  display: flex;
  flex-direction: column;
  background-color: #F7F7F7;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.companyListAndBasket {
  display: flex;
  gap: var(--margin-left-cart);
  padding-top: var(--margin-under-configurator);
  background-color: #F7F7F7;
}

.mainConfigurator {
  display: flex;
  flex-direction: column;
  padding: 10px 25px 20px;
}

.key_search__text__buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.key_search__faq__text {
  margin-right: 20px;
  line-height: 1.3;
}

.key_search__show__example {
  border-bottom: 1px dashed;
  font-weight: bold;
  cursor: pointer;
}

.key_search__attention__text {
  display: inline-block;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 16px;
}

.key_search__top_row {
  margin-top: 10px;
}

.geoTree-enter-active, .geoTree-leave-active {
  transition: opacity .3s;
  z-index: 400;
}
.geoTree-enter, .geoTree-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.wrapper-row{
  display: flex;
  justify-content: space-between;
}

.eb-active-tab-continuation-block-key-search {
  border: none;
  border-radius: 4px 0 4px 4px;
  box-shadow: rgb(153 153 153) 0 -1px 5px 0;
  background: var(--background-color-configurator);
}
</style>
