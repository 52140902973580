<template>
  <transition
    appear
    name="appear"
  >
    <div class="hintsBlock">
      <NotificationComponent
        class="hintsList"
        flipper-position="without-flipper"
        is-highlighted
      >
        <p v-if="isEgrul">
          Возможно, вы искали ОКВЭД {{ firstOkved }}.
          Поиск по ОКВЭД доступен в <a href="/okved" target="_blank">Базе ЕГРЮЛ.</a>
        </p>

        <div v-if="showSectionHint">
          <p>Советуем обратить внимание на следующие рубрики:</p>
          <div class="linkBlock">
            <p v-for="(section, index) in truncatedSectionList" :key="index">
              <a :href="'/?sId=' + section.id" target="_blank" class="pseudoCheckbox" />
              <a :href="'/?sId=' + section.id" target="_blank" class="name">{{ section.name }}</a>
            </p>
          </div>
          <div
            v-if="remainderCountOfSection > 0"
            class="remainderCountOfSectionBlock"
          >
            <a
              href="/?action=focusOnSearchInput"
              target="_blank"
            >и еще [{{ remainderCountOfSection }}
              {{ formattedNameOfRubric(remainderCountOfSection) }}]
            </a>
          </div>
          <p>
            Вы можете найти их на главной вкладке в разделе
            <a
              href="/"
              target="_blank"
            >База компаний</a>
          </p>
        </div>

        <p v-if="simplifyRequest">
          Возможно, искомый запрос слишком сложный, попробуйте изменить или упростить его.
        </p>

        <p v-if="notEnoughSymbols && !isRegistrationForm && !isFindCompanies">
          При вводе менее 3 символов поиск не осуществляется.
        </p>

        <p v-if="nothingSelectedCities">
          Необходимо выбрать города
        </p>

        <p v-if="absenceSearchAction && !notEnoughSymbols && !isRegistrationForm && !isFindCompanies">
          Помимо стоп-слова необходимо указать слово для поиска.
        </p>

        <p v-if="isRegistrationForm">
          Для поиска ООО/ПАО/АО советуем обратиться в раздел
          <a href="/okved" target="_blank">ЕГРЮЛ</a>
        </p>
      </NotificationComponent>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { pluralize } from 'numeralize-ru';
import NotificationComponent from '../../../../common_components/NotificationComponent/NotificationComponent';

export default {
  name: 'Hints',
  components: {
    NotificationComponent,
  },

  data() {
    return {
      isEgrul: false,
      firstOkved: null,

      queryIsComplex: false,
      notEnoughSymbols: false,
      absenceSearchAction: false,
      isRegistrationForm: false,
    };
  },

  computed: {
    ...mapState([
      'sectionsList',
      'searchBarObjects',
      'allowParseSearchString',
      'countOfCompanies',
      'cities',
    ]),

    ...mapGetters('geoTree', ['hasSelectedCities']),

    findSections() {
      return this.sectionsList.length;
    },

    nothingSelectedCities() {
      return !this.hasSelectedCities;
    },

    simplifyRequest() {
      return (
        !this.findSections
        && !this.isEgrul
        && this.countOfCompanies === 0
        && this.queryIsComplex
      );
    },

    isFindCompanies() {
      return this.countOfCompanies !== 0;
    },

    truncatedSectionList() {
      return this.sectionsList.filter((element, index) => index < 5);
    },

    remainderCountOfSection() {
      return this.sectionsList.length - 5;
    },

    showSectionHint() {
      return (
        this.findSections
          && !this.notEnoughSymbols
          && !this.absenceSearchAction
          && !this.isRegistrationForm
          && !this.isFindCompanies
      );
    },
  },

  watch: {
    allowParseSearchString() {
      if (this.allowParseSearchString) {
        this.parseString();
      }

      this.setPermitStringParsing(false);
    },
  },

  created() {
    this.OKVED_REGEXP = /(\d){1,2}\.(\d){1,2}(\.(\d){1,2})?/;
  },

  methods: {
    ...mapMutations([
      'setPermitStringParsing',
    ]),

    formattedNameOfRubric(count) {
      return pluralize(count, 'рубрика', 'рубрики', 'рубрик');
    },

    checkComplexity() {
      return this.searchBarObjects.some((searchBar) => searchBar.search.split(/\S+/).length > 3);
    },

    checkCountOfSymbolSearch() {
      const countNotEnoughSymbolSearch = this.searchBarObjects
        .filter((searchBar) => searchBar.search.length <= 2).length;
      return this.searchBarObjects.length === countNotEnoughSymbolSearch;
    },

    checkSearchAction() {
      return this.searchBarObjects.every((searchBar) => (searchBar.action !== 'search'));
    },

    checkRegistrationForm() {
      const countRegForm = this.searchBarObjects
        .filter((searchBar) => searchBar.search.trim().match(/^ИП$|^АО$|^ПАО$|^ООО$|^ЗАО$|^ОАО$/giu)).length;

      return this.searchBarObjects.length === countRegForm;
    },

    parseString() {
      this.queryIsComplex = this.checkComplexity();
      this.isRegistrationForm = this.checkRegistrationForm();
      this.notEnoughSymbols = this.checkCountOfSymbolSearch();
      this.absenceSearchAction = this.checkSearchAction();

      const okvedSearch = this.searchBarObjects
        .map((searchBar) => searchBar.search.match(this.OKVED_REGEXP))
        .filter((res) => res !== null);
      this.isEgrul = (okvedSearch.length > 0);
      this.firstOkved = this.isEgrul ? okvedSearch[0][0] : null;
    },
  },
};
</script>

<style scoped lang="less">
.appear-enter-active, .appear-leave-active {
  transition: all 0.6s linear;
  max-height: 200px;
  z-index: -1;
}

.appear-enter, .appear-leave-to {
  max-height: 0;
  opacity: 0;
}

.hintsBlock {
  width: 65%;
  min-width: 470px;
  margin-left: 10px;

  .hintsList {
    display: flex;
    flex-direction: column;
    gap: 15px;

    * {
      font-style: normal;
    }

    a {
      color: #353535;
    }

    .linkBlock {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0 10px 5px;

      p {
        padding: 4px 0;
        display: flex;
        align-items: center;

        a.name {
          text-decoration: none;
          border-bottom: 1px dashed #353535;
          margin-left: 7px;
        }

        .pseudoCheckbox {
          display: inline-block;
          width: 12px;
          height: 12px;
          border: 1px #353535 solid;
          border-radius: 4px;
        }
      }

      p:hover {
        .pseudoCheckbox {
          background-color: var(--color-button-prime-hover);
          box-shadow: 0 0 6px 2px white;
        }
      }
    }
  }
}

.remainderCountOfSectionBlock {
  display: flex;
  justify-content: flex-end;

  a {
    color: #ffae00;
    border-bottom: 1px dashed #353535;
    font-weight: bold;
    cursor: pointer;
    margin: -10px 15px 10px 0;
    font-style: italic;
    text-decoration: none;
  }
}
</style>
