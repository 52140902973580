<template>
  <div
    class="checkboxContainer"
    :class="{'checked': checked}"
    @click="change"
  >
    <span class="checkmark">
      <img
        src="../../../../../../assets/images/tick_icon.png"
        width="16"
        height="16"
        class="tick"
        alt="tick"
      >
    </span>
    <span class="text">Точное соответствие</span>
  </div>
</template>

<script>
export default {
  name: 'SearchMatchCheckbox',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  methods: {
    change() {
      this.$emit('change');
    },
  },
};
</script>

<style scoped lang="less">
.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  height: 13px;
  width: 13px;
  border: 1px solid #999;
  border-radius: 4px;
  margin-right: 5px;
}

.tick {
  display: none;
  width: 16px;
  height: 16px;
  position: relative;
  left: 10%;
  top: -20%;
}

.text {
  border-bottom: 1px dashed;
}

.checkboxContainer.checked {
  .tick {
    display: block;
  }
}
</style>
