<template>
  <div class="companyListBlockWrapper">
    <Preloader />

    <div
      class="companyListBlock"
      :class="{'isPreload': isPreload}"
    >
      <div class="key_search__result_text__wrapper">
        <div
          v-if="showCompanyList"
          class="key_search__result_text"
        >
          <div
            v-if="searchWords.length > 0"
            class="key_search_result_wrapper"
          >
            Поиск по:
            <ul class="key_search__result_list">
              <li v-for="word in searchWords">
                <span class="key_search__result_item">{{ word.search }}</span>
              </li>
            </ul>
          </div>
          <div
            v-if="stopWords.length > 0"
            class="key_search_result_wrapper"
          >
            Исключено:
            <ul class="key_search__result_list">
              <li v-for="word in stopWords">
                <span class="key_search__result_item key_search__stop">{{ word.search }}</span>
              </li>
            </ul>
          </div>
          <div class="key_search__result_companies">
            <b>Найдено: </b>
            <span class="countOfCompanies">{{ countOfCompanies.toLocaleString('ru') }}</span>
            {{ formattedNameOfCompany(countOfCompanies) }}
          </div>
        </div>
        <div
          v-if="showNotFoundMessage"
          class="key_search__result_text"
        >
          По вашему запросу ничего не найдено, измените параметры поиска.
        </div>
        <div
          v-if="!showCompanyList && !showNotFoundMessage"
          class="key_search__result_text"
        >
          Введите ключевое слово или ИНН в строку поиска выше, и нажмите "Поиск"
        </div>
      </div>

      <div
        v-if="showNotFoundMessage"
        class="emptyList"
      >
        Пусто, по выбранным критериям нет фирм
      </div>

      <div
        v-for="(company, index) in companies"
        :key="index"
        v-if="showCompanyList"
        class="companyList"
      >
        <p class="companyName">
          <b>
            <a
              v-if="hasInnOrOgrn(company)"
              :href="'/inn_search#?' + getInnOrOgrnId(company)"
              target="_blank"
              class="name"
              :title="getCompanyName(company)"
            >
              {{ getCompanyName(company) }}
            </a>
            <span
              v-else
              :title="getCompanyName(company)"
              class="companyNameWithoutLink"
              @click="clickOnCompanyWithoutLink"
            >
              {{ getCompanyName(company) }}
            </span>
          </b>
        </p>

        <a
          v-if="hasInnOrOgrn(company)"
          :href="'/inn_search#?' + getInnOrOgrnId(company)"
          target="_blank"
          class="linkBlock"
        >
          <p>
            <span>
              <b>{{ company.city }}</b>,
              <span
                :title="company.address"
                v-html="company.address"
              ></span>
            </span>
          </p>

          <p class="dataLine">
            <span class="dataCell">
              <b>Телефон</b> &mdash; <span v-html="getPhone(company)"></span>
            </span>
          </p>

          <p
            v-if="!noData(company.email)"
            class="dataLine"
          >
            <span class="dataCell">
              <b>Email</b> &mdash; <span v-html="getValueEmail(company.email)"></span>
            </span>
          </p>

          <p v-if="!noData(company.inn) || !noData(company.ogrn)" class="dataLine">
            <span
              v-if="!noData(company.inn)"
              class="dataCell"
            >
              <b>ИНН</b> &mdash; <span v-html="company.inn"></span>
            </span>
            <span
              v-if="!noData(company.ogrn)"
              class="dataCell"
            >
              <b>ОГРН</b> &mdash; <span v-html="company.ogrn"></span>
            </span>
          </p>
          <p v-if="!noData(company.okved_contact_person)">
            <span>
              <b>Руководитель (по ЕГРЮЛ)</b> &mdash;
              <span v-html="company.okved_contact_person"></span>
            </span>
          </p>
          <p>
            <span>
              <b>Сайт</b> &mdash; <span v-html="getValueSite(company.site)"></span>
              <span v-if="!noData(company.social_media)">,&nbsp;
                <span v-html="company.social_media"></span>
              </span>
            </span>
          </p>
        </a>

        <div
          v-else
          class="linkBlockWithoutLink"
          @click="clickOnCompanyWithoutLink"
        >
          <p>
            <span>
              <b>{{ company.city }}</b>,
              <span
                :title="company.address"
                v-html="company.address"
              ></span>
            </span>
          </p>
          <p class="dataLine">
            <span class="dataCell">
              <b>Телефон</b> &mdash; <span v-html="getPhone(company)"></span>
            </span>
          </p>
          <p
            v-if="!noData(company.email)"
            class="dataLine"
          >
            <span class="dataCell">
              <b>Email</b> &mdash; <span v-html="getValueEmail(company.email)"></span>
            </span>
          </p>
          <p
            v-if="!noData(company.inn) || !noData(company.ogrn)"
            class="dataLine"
          >
            <span
              v-if="!noData(company.inn)"
              class="dataCell"
            >
              <b>ИНН</b> &mdash; <span v-html="company.inn"></span>
            </span>
            <span
              v-if="!noData(company.ogrn)"
              class="dataCell"
            >
              <b>ОГРН</b> &mdash; <span v-html="company.ogrn"></span>
            </span>
          </p>
          <p v-if="!noData(company.okved_contact_person)">
            <span>
              <b>Руководитель (по ЕГРЮЛ)</b> &mdash;
              <span v-html="company.okved_contact_person"></span>
            </span>
          </p>

          <p>
            <span>
              <b>Сайт</b> &mdash; <span v-html="getValueSite(company.site)"></span>
              <span v-if="!noData(company.social_media)">,&nbsp;
                <span v-html="company.social_media"></span>
              </span>
            </span>
          </p>
        </div>

        <p v-if="!noData(company.title)">
          <b>Заголовок сайта</b> &mdash;
          <span
            :title="company.title"
            v-html="content(highlightedCompaniesData[index].title, company.title)"
          ></span>
        </p>
        <p v-if="!noData(company.description)"><b>Описание сайта</b> &mdash;
          <span
            :title="company.description"
            v-html="content(highlightedCompaniesData[index].description, company.description)"
          ></span>
        </p>
        <p v-if="!noData(company.wbLink)">
          <span>
            <b>Компания на WildBerries</b> &mdash;
            <a target="_blank" :href="company.wbLink">Ссылка</a>
          </span>
        </p>
        <p v-if="!noData(company.ozonLink)">
          <span>
            <b>Компания на Озон</b> &mdash; <a target="_blank" :href="company.ozonLink">Ссылка</a>
          </span>
        </p>
        <p v-if="!noData(company.yandexLink)">
          <span>
            <b>Компания на Яндекс Маркете</b> &mdash;
            <a target="_blank" :href="company.yandexLink">Ссылка</a>
          </span>
        </p>
      </div>
      <div class="paginationAndCompaniesCountBlock" v-if="showCompanyList">
        <div class="paginationBlock" v-if="numberOfPages > 1">
          <span>Страница выдачи:</span>
          <paginate
            :page-count="numberOfPages"
            :click-handler="changePage"
            :value="currentPage"
            prev-text=""
            next-text=""
            container-class="paginateContainer"
            page-class="paginatePage"
          >
          </paginate>
        </div>
        <div class="companiesCountBlock">
          <span>Всего компаний:</span>
          <span class="countOfCompanies">{{ countOfCompanies.toLocaleString('ru') }} шт</span>
          <img
            src="./img/poisk_down.png"
            width="180"
            height="54"
            alt=""
            @click="scrollUp"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { pluralize } from 'numeralize-ru';
import Preloader from '../Preloader/Preloader.vue';

export default {
  name: 'CompanyList',
  components: {
    Preloader,
  },

  data() {
    return {
      searchBarArray: [],
    };
  },

  computed: {
    ...mapState([
      'companies',
      'highlightedCompaniesData',
      'searchBarObjects',
      'countOfCompanies',
      'showContent',
      'numberOfPages',
      'formattedSearchString',
      'currentPage',
      'isPreload',
    ]),

    showCompanyList() {
      return this.showContent && this.countOfCompanies !== 0;
    },

    showNotFoundMessage() {
      return this.showContent && this.countOfCompanies === 0;
    },

    searchWords() {
      return this.searchBarArray.filter((word) => word.action === 'search' && word.search.length >= 3);
    },

    stopWords() {
      return this.searchBarArray.filter((word) => word.action === 'stop' && word.search.length >= 3);
    },
  },

  beforeUpdate() {
    this.searchBarArray = JSON.parse(JSON.stringify(this.searchBarObjects));
  },

  methods: {
    ...mapMutations(['setCurrentPage']),
    ...mapActions(['searchCompany']),

    getValue(value) {
      if (value === 'Нет данных') {
        return value;
      }
      return value[0];
    },

    formattedNameOfCompany(count) {
      return pluralize(count, 'компания', 'компании', 'компаний');
    },

    getValueSite(value) {
      if (value === 'Нет данных') {
        return value;
      }
      return value.join(', ');
    },

    getValueEmail(value) {
      if (value === 'Нет данных') {
        return value;
      } else if (value.length >= 2) {
        return value[0] + '<i> еще ' + (value.length - 1) + ' email </i>';
      } else {
        return value[0];
      }
    },

    getValuePhone(value) {
      if (value === 'Нет данных') {
        return value;
      } else if (value.length >= 2) {
        return value[0] + '<i> еще ' + (value.length - 1) + ' тел. </i>';
      } else {
        return value[0];
      }
    },

    hasInnOrOgrn({ inn, ogrn }) {
      return inn !== 'Нет данных' || ogrn !== 'Нет данных';
    },

    content(processed, raw) {
      return processed === '' ? raw : processed;
    },

    noData(data) {
      return data === 'Нет данных' || data == null;
    },

    getPhone(company) {
      if (this.noData(company.mobile_phone)) {
        return this.getValuePhone(company.stationary_phone);
      }

      return this.getValuePhone(company.mobile_phone);
    },

    getInnOrOgrnId({ sccgId, coId }) {
      if (sccgId !== undefined) {
        return `sccgId=${sccgId}`;
      }

      return `coId=${coId}`;
    },

    getCompanyName(company) {
      return company.name ? company.name : company.legal_name;
    },

    changePage(page) {
      const MAX_PAGE_NUMBER = 2;
      if (page > MAX_PAGE_NUMBER) {
        // eslint-disable-next-line no-alert
        window.alert(`Данные о всех компаниях доступны после оплаты.\nДля демонстрации показаны первые ${MAX_PAGE_NUMBER} страницы.`);
        return;
      }
      this.setCurrentPage(page);
      this.searchCompany(false);
    },

    scrollUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    clickOnCompanyWithoutLink() {
      // eslint-disable-next-line no-alert
      window.alert('К сожалению, у этой компании нет страницы с детализацией');
    },
  },
};
</script>

<style scoped lang="less">
.companyListBlockWrapper {
  width: calc(100% - 390px - var(--margin-left-cart));
}

.companyListBlock {
  display: flex;
  flex-direction: column;
  padding-left: 2px;
  &.isPreload {
    opacity: .6;
  }
}

.emptyList {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 60px;
  background: url("./img/empty_box.png") center left no-repeat;
  background-size: contain;
}

.companyList {
  margin-bottom: 20px;

  .companyName {
    font-size: 18px;
    margin-bottom: 7px;

    .companyNameWithoutLink {
      color: #000000;
      &:hover {
        text-decoration: none;
      }
    }

    img {
      position: relative;
      top: 4px;
    }

    * {
      color: #1b88c1;
    }
  }

  p {
    padding: 5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .name {
    display: inline-block;
    max-width: 375px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dataLine {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dataCell:first-child {
    margin-right: 10px;
  }
}

.key_search__result_text__wrapper {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 #999;
  background-color: var(--background-color-configurator);
  margin-bottom: 20px;
}

.key_search__result_companies{
  font-size: 18px;
}

.countOfCompanies {
  color: var(--color-green-prime);
}

.key_search__result_text {
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 14px;
  border-radius: 5px;
  padding: 15px;
  background-color: var(--background-color-configurator);
}

.key_search__result_item{
  padding: 0 8px 2px;
  border: 1px solid #DDDDDD;
  border-radius: 15px;
  color: #333;
}

.key_search__stop{
  border: 1px solid #9F2222;
  color: #9F2222;
}

.key_search_result_wrapper{
  display: flex;
  gap: 10px;
}

.key_search__result_list{
  max-width: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.paginationBlock {
  display: flex;
  align-items: center;
  font-weight: bold;

  :deep(.paginateContainer) {
    display: flex;
    margin-left: 20px;
    font-weight: bold;
  }

  :deep(.paginatePage) {
    margin: 0 5px;
    padding: 7px 9px;
    border-radius: 2px;
  }

  :deep(.active) {
    background-color: #a8c161;

    a {
      color: white;
    }
  }
}

.companiesCountBlock {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 5px;

  .countOfCompanies {
    margin-left: 35px;
    margin-right: 35px;
  }

  img {
    cursor: pointer;
  }
}

.linkBlock {
  display: block;
  cursor: pointer;
  text-decoration: none;

  & * {
    cursor: pointer;
  }
}

.linkBlockWithoutLink {
  cursor: pointer;
  & * {
    cursor: pointer;
  }
}

.product-link{
  text-decoration: none;
}

@media (max-width: 1050px) and (min-width: 800px) {
  .companyList .name {
    max-width: 300px;
  }
}
</style>
