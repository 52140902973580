function catchError(e) {
  console.error(e);

  if (e.response.status === 403) {
    window.echo(
        `К сожалению, сервис загружен и не может обработать запрос. 
            Наш телефон: <a href="tel:+78007752912" style="color: white" target="_blank">8 800 775-29-12</a>`,
        120000,
        'error'
    );
  } else {
    window.echo(
        `Произошла техническая ошибка. 
            Наш телефон: <a href="tel:+78007752912" style="color: white" target="_blank">8 800 775-29-12</a>`,
        120000,
        'error'
    );
  }
}

export { catchError };
