import { createStore } from 'vuex';
import api from '../api/api';
import { catchError } from '../api/helper';
import CompanyDataStylist from '../helper/CompanyDataStylist';
import geoTree from './modules/geoTree/index';
import payPopup from "@/key_search/KeySearchConfigurator/store/modules/payPopup";

const store = createStore({
  modules: {
    geoTree,
    payPopup,
  },
  state: {
    visibleGeoTree: false,

    isPreload: false,
    newSearchNeeded: false,
    searchBarObjects: [
      {
        search: '',
        action: 'search',
        typeOfSearch: 'any',
      },
    ],

    exactMatchSearch: false,

    formattedSearchString: '',

    typesOfSearch: ['name', 'title', 'description'],

    companies: [],
    highlightedCompaniesData: [],
    searchParialWords: [],
    countOfCompanies: 0,
    cost: 0,
    costWithoutDiscount: 0,
    discountPercentage: 0,

    showContent: false,
    allowParseSearchString: false,

    currentPage: 1,
    numberOfPages: 0,


    sectionsList: [],

    // выбранные пользователем города
    // Россия по умолчанию
    cities: [{ name: 'Россия', action: 'search', id: 1 }],

    // список всех городов для выбора пользователем
    citiesList: [],

    paymentMethods: ['payment_systems', 'cashless', 'deposit'],
    chosenPaymentMethod: 'payment_systems',
  },

  mutations: {
    setCities(state, value) {
      state.cities = value;
    },
    showContent(state) {
      state.showContent = true;
    },

    showLoader(state) {
      state.isPreload = true;
    },

    hideLoader(state) {
      state.isPreload = false;
    },

    setNewSearchNeeded(state, val) {
      state.newSearchNeeded = val;
    },

    setSearchPartialWords(state, array) {
      state.searchParialWords = array;
    },

    setSearchString(state, [searchString, index]) {
      state.searchBarObjects[index].search = searchString;
    },

    setAction(state, [action, index]) {
      state.searchBarObjects[index].action = action;
    },

    setTypeOfSearch(state, [typeOfSearch, index]) {
      state.searchBarObjects[index].typeOfSearch = typeOfSearch;
    },

    deleteSearchBar(state, index) {
      state.searchBarObjects.splice(index, 1);
    },

    changeSearchMatch(state) {
      state.exactMatchSearch = !state.exactMatchSearch;
    },

    deleteAllSearchBars(state) {
      state.searchBarObjects = [
        {
          search: '',
          action: 'search',
          typeOfSearch: 'any',
        },
      ];
    },

    addSearchBar(state) {
      state.searchBarObjects.push({
        search: '',
        action: 'search',
        typeOfSearch: 'any',
      });
    },

    setExampleSearchBarState(state) {
      state.searchBarObjects = [
        {
          search: 'консалтинг',
          action: 'search',
          typeOfSearch: 'any',
        },
        {
          search: 'разработка',
          action: 'stop',
          typeOfSearch: 'any',
        },
      ];
    },

    setCompanies(state, companies) {
      state.companies = companies;
    },

    resetHighlightedCompaniesData(state) {
      if (state.highlightedCompaniesData.length > 0) {
        state.highlightedCompaniesData.splice(0, state.highlightedCompaniesData.length);
      }
    },

    setHighlightedCompaniesData(state, companies) {
      const stylist = new CompanyDataStylist();
      for (const company of companies) {
        // объект, который будет хранить тексты с подсветкой, для одной компании
        const data = {};

        state.typesOfSearch.forEach((typeOfSearch) => { data[typeOfSearch] = ''; });

        // проходим по каждому объекту поискового поля,
        // в котором действие - "Искать", и при это не ищется пустая строка
        for (const searchBarObject of state.searchBarObjects) {
          if (searchBarObject.action === 'search' && searchBarObject.search !== '') {
            const any = searchBarObject.typeOfSearch === 'any';

            if (any) {
              // для "Везде" производится подсветка во всех поисковых областях,
              // если соответсвующие ключевые слова были найдены
              state.typesOfSearch.forEach((typeOfSearch) => {
                const searchPartialWord = state.searchParialWords
                  .filter(word => searchBarObject.search.match(word)).shift();

                if (data[typeOfSearch] !== undefined && company[typeOfSearch] !== undefined) {
                  data[typeOfSearch] = stylist.highlightSearchWord(
                    stylist.squeezeTextBeforeSearchWord(
                      company[typeOfSearch],
                      searchBarObject.search,
                      20,
                    ),
                    searchBarObject.search,
                    searchPartialWord,
                  );
                }
              });
            } else {
              // подсветка производится в конкретной поисковой области,
              // если соответсвующие ключевые слова были найдены
              const searchPartialWord = state.searchParialWords
                .filter(word => searchBarObject.search.match(word)).shift();

              if (
                data[searchBarObject.typeOfSearch] !== undefined
                && company[searchBarObject.typeOfSearch] !== undefined
              ) {
                data[searchBarObject.typeOfSearch] = stylist.highlightSearchWord(
                  stylist.squeezeTextBeforeSearchWord(
                    company[searchBarObject.typeOfSearch],
                    searchBarObject.search,
                    20,
                  ),
                  searchBarObject.search,
                  searchPartialWord,
                );
              }
            }

            if (stylist.isStyled()) {
              // если по запросу поискового поля удалось подсветить искомые ключевые слова,
              // то остальные поисковые поля не рассматриваются
              break;
            }
          }
        }

        stylist.resetStatus();
        state.highlightedCompaniesData.push(data);
      }
    },

    setCost(state, cost) {
      state.cost = cost;
    },

    setCountOfCompanies(state, count) {
      state.countOfCompanies = count;
    },

    setCurrentPage(state, numberOfPage) {
      state.currentPage = numberOfPage;
    },

    setNumberOfPages(state, count) {
      state.numberOfPages = count;
    },

    setSectionsList(state, sectionsList) {
      state.sectionsList = sectionsList;
    },

    setFormattedSearchString(state, formattedSearchString) {
      state.formattedSearchString = formattedSearchString;
    },

    setCostWithoutDiscount(state, cost) {
      state.costWithoutDiscount = cost;
    },

    setDiscountPercentage(state, percentage) {
      state.discountPercentage = percentage;
    },

    addCity(state, city) {
      state.cities.push(city);
    },

    removeCity(state, cityId) {
      state.cities.splice(state.cities.findIndex(city => city.id === cityId), 1);
    },

    setPermitStringParsing(state, permit) {
      state.allowParseSearchString = permit;
    },

    setCitiesList(state, list) {
      state.citiesList = list;
    },

    setVisibleGeoTree(state, value) {
      state.visibleGeoTree = value;
    },

    setPaymentMethod(state, index) {
      state.chosenPaymentMethod = state.paymentMethods[index];
    },
  },

  getters: {
    getGeoTree(state) {
      return state.tree;
    },
    tooShortQuery(state) {
      return state.searchBarObjects.every(searchBar => searchBar.search.length <= 2);
    },

    formatSearchQuery(state) {
      let searchPart = '';
      let stopPart = '';

      state.searchBarObjects.forEach((searchObject) => {
        if (searchObject.search === '') {
          return;
        }

        if (searchObject.action === 'search') {
          searchPart += `${searchObject.search} `;
        } else {
          stopPart += `${searchObject.search} `;
        }
      });

      if (stopPart === '') {
        return { searchPart: searchPart.trim() };
      }

      return {
        searchPart: searchPart.trim(),
        stopPart: stopPart.trim(),
      };
    },

    // важные города (типа Москва, Питер)
    importantCitiesList(state) {
      return state.citiesList.filter(city => city.important === 1);
    },
    // остальные города
    ordinaryCitiesList(state) {
      return state.citiesList.filter(city => city.important !== 1);
    },
    searchBarIsEmpty(state) {
      return state.searchBarObjects.every(object => object.search.length === 0);
    },
  },

  actions: {
    async searchCompany({
      commit, dispatch, state, getters,
    }, resetPageNumber = true) {
      if (!getters['geoTree/hasSelectedCities']) {
        commit('setCompanies', 0);
        commit('setSectionsList', []);
        commit('setSearchPartialWords', []);
        commit('setCountOfCompanies', 0);
        commit('setCost', 0);
        commit('setDiscountPercentage', 0);
        commit('setCostWithoutDiscount', 0);
        return false;
      }

      if (resetPageNumber) {
        commit('setCurrentPage', 1);
      }

      if (state.isPreload) {
        commit('setNewSearchNeeded', true);
        return;
      }

      try {
        commit('showLoader');
        const response = await api.getCompany(
          state.searchBarObjects,
          state.cities,
          state.currentPage,
          state.exactMatchSearch,
        );
        commit('hideLoader');

        if (state.newSearchNeeded) {
          commit('setNewSearchNeeded', false);
          dispatch('searchCompany');
          return;
        }

        commit('setCompanies', response.companies);
        commit('setNumberOfPages', response.numberOfPages);
        commit('setSectionsList', response.sectionsList);
        commit('setSearchPartialWords', response.searchArray);
        dispatch('setHighlightedCompaniesData', response.companies);
        commit('setCountOfCompanies', response.countOfCompanies);
        commit('setFormattedSearchString', getters.formatSearchQuery);
        commit('setCost', response.cost);
        commit('setDiscountPercentage', response.discountPercentage);
        commit('setCostWithoutDiscount', response.costWithoutDiscount);
        commit('showContent');
        commit('setPermitStringParsing', true);
      } catch (e) {
        catchError(e);
      }
    },

    setHighlightedCompaniesData({ commit }, companies) {
      commit('resetHighlightedCompaniesData');
      commit('setHighlightedCompaniesData', companies);
    },

    async getCitiesList({ commit }) {
      commit('setCitiesList', await api.getCitiesList());
    },

    changeSearchMatch({ commit, dispatch }) {
      commit('changeSearchMatch');
      dispatch('searchCompany');
    },

    addCity({ commit, dispatch }, city) {
      commit('addCity', city);
      dispatch('searchCompany');
    },

    removeCity({ commit, dispatch }, cityId) {
      commit('removeCity', cityId);
      dispatch('searchCompany');
    },

    setAction({ commit, dispatch }, data) {
      commit('setAction', data);
      dispatch('searchCompany');
    },

    setTypeOfSearch({ commit, dispatch }, data) {
      commit('setTypeOfSearch', data);
      dispatch('searchCompany');
    },

    deleteSearchBar({ commit, dispatch }, index) {
      commit('deleteSearchBar', index);
      dispatch('searchCompany');
    },

    deleteAllSearchBars({ commit, dispatch }) {
      commit('deleteAllSearchBars');
      dispatch('searchCompany');
    },
  },
});

export default store;
