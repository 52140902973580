<template>
  <div class="citiesList" @click="showGeoTree">
    <span><b>География выборки:</b></span>
    <ButtonSelector :content="chosenCities"
                    :is-important-selector="true"
                    width="200px"
                    font-size="17px"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import plural from 'plural-ru';
import ButtonSelector from 'button-selector';

export default {
  name: 'CitiesList',

  components: {
    ButtonSelector,
  },

  data() {
    return {
      isSelectorShow: false,
      borderedCityList: [],
    };
  },

  methods: {
    selectorChange() {
      this.isSelectorShow = !this.isSelectorShow;
    },

    ...mapMutations([
        'setVisibleGeoTree',
    ]),

    showGeoTree() {
      this.setVisibleGeoTree(true);
    },

    outsideEventListener(event) {
      if (event.target.className.includes('optionsList')) {
        return;
      }

      this.isSelectorShow = false;
    },

    setBorderedCityList() {
      let compareLetter = 'А';

      this.ordinaryCitiesList.forEach((city) => {
        let borderState = false;

        if (city.name[0] !== compareLetter) {
          borderState = true;
          compareLetter = city.name[0];
        }

        this.borderedCityList.push({...city, border: borderState });
      });
    },

    ...mapActions([
        'getCitiesList',
        'searchCompany'
    ]),
  },

  computed: {
    ...mapState(['cities', 'searchBarObjects']),
    ...mapGetters(['importantCitiesList', 'ordinaryCitiesList']),
    ...mapGetters('geoTree', ['treeFullSelected']),

    chosenCities() {
      if (this.treeFullSelected) {
        return 'Россия';
      } else if (this.cities.length === 1) {
        return this.cities[0].name;
      } else if (this.cities.length === 0) {
        return 'Города не выбраны';
      } else {
        return this.cities.length + ' ' + plural(this.cities.length, 'город', 'города', 'городов');
      }
    },
  },

  watch: {
    isSelectorShow() {
      if (this.isSelectorShow) {
        document.addEventListener('click', this.outsideEventListener);
      } else {
        document.removeEventListener('click', this.outsideEventListener);
      }
    },
  },

  async mounted() {
    this.setBorderedCityList();
  },
};
</script>

<style scoped src="../../css/reset.css"/>
<style scoped lang="less">
.citiesList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 25px 0 0 10px;
  align-items: start;
}

.buttonSelector {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 155px;
  height: 35px;
  padding-left: 10px;
  padding-right: 40px;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.75);
  background: url(/images/spisok.png) no-repeat right 0 #f7f7f7;
  background-size: 35px 100%;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 4px #333;
  }
}

.buttonSelectorText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.optionsList {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  z-index: 15;
  width: 230px;
  height: 300px;
  overflow-y: scroll;
  top: 100%;
  left: 0;
  margin-top: 5px;
  box-shadow: 1px 40px 125px #666;

  .importantList {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }

  span {
    padding: 6px 5px;
    cursor: pointer;

    &.important {
      font-weight: bold;
    }

    &:hover {
      background-color: rgba(145,173,44, 0.55);
      color: #fff;
    }

    &.selected {
      background-color: var(--color-button-prime-hover);
      color: #fff;
    }

    &.borderCity {
      font-weight: bold;
      border-top: 1px solid #ccc;
    }
  }
}

.key_search__delete_all_button {
  position: absolute;
  bottom: -40px;
  right: 0;
}

.key_search__column__buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 10px 0 auto;
}

.slide-enter-active, .slide-leave-active {
  transition: opacity 1s;
}
.slide-enter, .slide-leave-to {
  opacity: 0;
}
</style>
