<template>
  <div class="row-format">
    <p class="row-format__description">
      Формат:
    </p>
    <p class="row-format__value">
      .xlsx (Excel), .csv (1C-Битрикс)
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowFormat',
}
</script>

<style>
.row-format{
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: center;
}

.row-format__value{
  font-weight: normal;
}
</style>