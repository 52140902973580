// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/preloader.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.preloader[data-v-89a50654] {\n  position: absolute;\n  top: 90px;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 100;\n  width: 170px;\n  height: 170px;\n  margin: 0;\n  background-color: var(--background-color-configurator);\n  border-radius: 4px;\n  box-shadow: 0 1px 2px grey;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n}\n.preloaderImg[data-v-89a50654] {\n  width: 100px;\n  height: 100px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100px 100px;\n  align-self: center;\n}\n.preloaderText[data-v-89a50654] {\n  font-size: 16px;\n  font-weight: bold;\n  align-self: center;\n}\n", ""]);
// Exports
module.exports = exports;
