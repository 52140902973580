<template>
  <div class="row-demo">
    <p class="row__description">
      Демо-файл:
    </p>
    <p class="row-demo__value">
      В качестве демо показаны 2 страницы с данными компаний
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowDemo',
}
</script>

<style>
.row-demo{
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: start;
}

.row-demo__value{
  font-weight: normal;
}
</style>