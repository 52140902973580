<template>
  <div class="row-fields">
    <p class="row-fields__description">
      Поля:
    </p>
    <ul class="row-fields-list">
      <li
          v-for="(filter, index) in filters"
          class="row-fields-item"
          :key="index"
      >
        <img
            alt="mark"
            src="/dist/css/images/tick_small.png"
            class="row-fields__mark"
        />
        <p
            class="row-fields__text"
        >
          {{ filter }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RowFields',

  data() {
    return {
      filters: [
        'Рубрика/Подрубрика',
        'Руководитель (по ЕГРЮЛ)',
        'ИНН/ОГРН',
        'Телефон компании',
        'Email компании',
        'Сайт',
        'Адрес/Индекс',
        'Выручка',
        'Размер компании',
        'Кол-во сотрудников',
      ],
    }
  }
}
</script>

<style>
.row-fields{
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: flex-start;
}

.row-fields-item{
  display: flex;
  align-items: center;
  font-weight: normal;
}
</style>
