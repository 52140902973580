<template>
  <div
    class="eb-button-secondary small-button"
    @click="deleteAllSearchBars"
  >
    Очистить
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeleteAllButton',

  methods: {
    ...mapActions([
        'deleteAllSearchBars',
    ]),
  },
};
</script>
