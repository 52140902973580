<template>
  <TwoBlocksPopup @close-popup="hideGeoTree">
    <template #popup-left-block>
      <p class="change-cities__title">
        Выберите нужные города:
      </p>
      <SearchBar
        v-model="searchStringModel"
        class="searchBar"
        width="97%"
        placeholder="Поиск города/региона"
        @reset="resetSearch"
        @click="setTooltipVisible(null)"
      />
      <div
        v-if="!isSearchMode"
        class="tree-component-wrapper scrollable-Y"
      >
        <img
          class="flag"
          src="/images/country1.png"
          alt="flag"
        >
        <TreeComponent
          :node="getGeoTree.getRoot()"
          with-important-nodes
          :checkbox-node-mode="{'text-bold': 2}"
          :important-node-mode="['tree-city-center']"
          :extra-node-mode="['tree-city', 'mv-15']"
          :close-btn="{
            maxVisibleItems: 12,
            nodeLevel: 3,
            textBtn: ['город', 'города', 'городов'],
          }"
          :chosen-node-mode="['mark']"
          :ul-node-mode="{'clean-tree-ul': 2,}"
          class="geo-tree"
          @expand-node="expandGeoNode"
          @choose-node="chooseNode"
          @click="setTooltipVisible(null)"
        />
        <transition name="fade">
          <div
            v-if="tooltipVisible"
            class="main-block__tooltip"
          >
            По умолчанию выбрана вся Россия
          </div>
        </transition>
      </div>
      <div
        v-if="isSearchMode"
        class="search-tree scrollable-Y"
      >
        <span
          v-if="emptySearchResult"
          class="emptySearch"
        >Ничего не найдено</span>
        <TreeComponent
          v-else
          :node="getGeoSearchTree.getRoot()"
          :search-text="searchStringModel"
          :ul-node-mode="{'tree-ul-pa-left-20': 0}"
          :expandable="false"
          class="geo-tree"
          @expand-node="expandGeoSearchNode"
          @choose-node="chooseSearchNode"
        />
      </div>
    </template>
    <template #popup-right-block>
      <p class="city-tree-description__title">
        Как провести конфигурацию городов?
      </p>
      <p class="city-tree-description__text">
        1. Выберите один или несколько городов для начала конфигурации.
      </p>
      <p class="city-tree-description__text">
        2. Вы можете воспользоваться поиском, чтобы найти нужный город.
      </p>
      <div class="search-block__wrapper-btns">
        <div
          class="eb-button-prime small-button"
          :class="{'disabled': !hasSelectedCities}"
          @click="chooseCities"
        >
          Выбрать
        </div>
        <div class="eb-button-secondary small-button" @click="resetChanges">
          Сброс
        </div>
      </div>
    </template>
  </TwoBlocksPopup>
</template>

<script>
import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';
import { TreeComponent } from 'tree';
import SearchBar from 'searchbar';
import TwoBlocksPopup from '@/common_components/TwoBlocksPopup/TwoBlocksPopup.vue';

export default {
  name: 'GeoTree',
  components: {
    TwoBlocksPopup,
    TreeComponent,
    SearchBar,
  },

  computed: {
    ...mapState('geoTree', [
      'searchString',
    ]),

    ...mapGetters('geoTree', [
      'getGeoTree',
      'getGeoSearchTree',
      'isSearchMode',
      'emptySearchResult',
      'selectedGeoUnits',
      'tooltipVisible',
      'hasSelectedCities',
    ]),

    searchStringModel: {
      get() {
        return this.searchString;
      },

      set(value) {
        this.updateSearch(value);
      },
    },
  },

  watch: {
    selectedGeoUnits: {
      handler() {
        this.setCities(this.selectedGeoUnits);
        this.searchCompany();
      },

      deep: true,
    },
  },

  mounted() {
    if (this.tooltipVisible !== null) {
      this.setTooltipVisible(true);
    }
  },

  methods: {
    ...mapMutations('geoTree', [
      'expandGeoNode',
      'expandGeoSearchNode',
      'resetSearch',
      'chooseOrCancelTree',
      'chooseOrCancelSearchTree',
      'setTooltipVisible',
    ]),

    ...mapActions('geoTree', [
      'chooseGeoSearchNode',
      'updateSearch',
      'chooseGeoNode',
    ]),

    ...mapMutations([
      'setVisibleGeoTree',
      'setCities',
    ]),

    ...mapActions(['searchCompany']),

    chooseSearchNode(nodeDesc) {
      this.chooseGeoSearchNode(nodeDesc);
    },

    chooseNode(nodeDesc) {
      this.chooseGeoNode(nodeDesc);
    },

    chooseCities() {
      if (this.hasSelectedCities) {
        this.setCities(this.selectedGeoUnits);
        this.hideGeoTree();
      }
    },

    resetChanges() {
      this.chooseOrCancelTree(false);
      this.chooseOrCancelSearchTree(false);
    },

    hideGeoTree() {
      this.resetSearch();
      this.setVisibleGeoTree(false);
    },
  },
};
</script>

<style scoped>
.geo-tree {
  padding-left: 30px;
  padding-bottom: 30px;
}

.change-cities__title {
  margin-top: 5px;
  font-size: 20px;
  font-weight: bold;
}

.search-block__wrapper-btns {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
}

.flag {
  position: absolute;
  left: 5px;
  width: 20px;
}

.city-tree-description__title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 70px;
}

.city-tree-description__text {
  margin-bottom: 12px;
}

.main-block__tooltip {
  position: absolute;
  top: 0;
  left: 115px;
  width: 205px;
  background: #424242;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-style: italic;
  font-size: 14px;
  z-index: 10;
}

.main-block__tooltip::before {
  content: '';
  position: absolute;
  left: -9px;
  top: 6%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #424242;
}
</style>
