import axios from 'axios';

export default {
  async getCompany(searchObject, cities, pageNumber, searchMatchType) {
    return (await axios.post('/api/company_search_by_key/company', {
      searchObject,
      cities,
      pageNumber,
      searchMatchType,
    })).data;
  },

  async createOrder(searchObject, cities, userEmail, searchMatchType, cashless = null) {
    return (await axios.post('/api/company_search_by_key/create_order', {
      searchObject,
      cities,
      userEmail,
      searchMatchType,
      cashless,
    })).data;
  },

  async getLastUpdate() {
    return (await axios.get('/api/latest-update')).data;
  },

  checkAuth() {
    return fetch('api/client_is_authenticated', {method: 'GET'})
        .then(res => res.json());
  },

  getUserData() {
    return fetch('/api/authorized_client_data', {method: 'GET'})
        .then(res => res.json());
  },

  getUserDiscount(email) {
    return fetch('/handlers/email_discount.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        email: email,
      }),
    })
        .then(res => res.json())
        .catch(() => {
          echo(
              `Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)`,
              6000,
              'error',
          );
        })
  },

  async getClientBalance() {
    const res = await fetch('/api/personal_cabinet/balance', {method: 'GET'});
    return await res.text();
  },

  async getClientEmail() {
    const res = await fetch(
      '/api/component/cashless_payment_form/user_email',
      {method: 'GET'}
    );
    if (res.ok) {
      return await res.text();
    } else {
      return '';
    }
  },

  async getCitiesList() {
    return (await axios.get('/api/company_search_by_key/cities')).data;
  },

  async getTerritories() {
    return (await axios.get('/api/company_search_by_key/territories')).data;
  },

  async createBill(data) {
    const res = await axios.get('/api/bill', {
      params: {
        nazv: data.nazv,
        cost_for_beznal: data.cost_for_beznal,
        umb_schet: data.umb_schet + 'П',
        inn: data.inn,
        count_for_beznal: data.count_for_beznal,
        director: data.director,
        search_for_key: true,
      },
    });

    return window.location.href = res.request.responseURL;
  },
};
