<template>
  <div class="preloader" v-if="isPreload">
    <div class="preloaderText">Поиск компаний...</div>
    <div class="preloaderImg"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Preloader',

  computed: {
    ...mapState(['isPreload'])
  },
};
</script>

<style scoped>
.preloader {
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 170px;
  height: 170px;
  margin: 0;
  background-color: var(--background-color-configurator);
  border-radius: 4px;
  box-shadow: 0 1px 2px grey;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.preloaderImg {
  width: 100px;
  height: 100px;
  background-image: url('./img/preloader.gif');
  background-size: 100px 100px;
  align-self: center;
}

.preloaderText {
  font-size: 16px;
  font-weight: bold;
  align-self: center;
}
</style>
