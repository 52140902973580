import api from '@/key_search/KeySearchConfigurator/api/api';
import { catchError } from '@/key_search/KeySearchConfigurator/api/helper';

export default {
  namespaced: true,

  state: {
    email: '',
    orderId: '',
    clientId: 0,
    clientBalance: 0,
    customerIsAuthorized: false,
    visiblePayPopup: false,
    userDiscount: 0,
    initialTab: '',
    showOrderPreloader: false,
  },
  getters: {
  },
  mutations: {
    setOrderId(state, value) {
      state.orderId = value;
    },
    setClientId(state, value) {
      state.clientId = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setAuthStatus(state, value) {
      state.customerIsAuthorized = value;
    },
    setUserBalance(state, balance) {
      state.clientBalance = balance;
    },
    setVisiblePayPopup(state, value) {
      state.visiblePayPopup = value;
    },
    setUserDiscount(state, value) {
      state.userDiscount = value;
    },
    setInitialTab(state, value) {
      state.initialTab = value;
    },
    setShowOrderPreloader(state, value) {
      state.showOrderPreloader = value;
    },
  },
  actions: {
    async getAuthStatus({ commit }) {
      const auth = await api.checkAuth();
      commit('setAuthStatus', Boolean(auth));
    },
    async getUserData({ commit }) {
      const userData = await api.getUserData();
      commit('setEmail', userData.email);
      commit('setUserBalance', userData.balance);
    },

    async getUserDiscount({ commit }, email) {
      const discount = await api.getUserDiscount(email);
      commit('setUserDiscount', discount.disc);
    },

    async createBill({ commit, rootState }, data) {
      try {
        const response = await api.createOrder(
          rootState.searchBarObjects,
          rootState.cities,
          data.email_for_beznal,
          rootState.exactMatchSearch,
          data,
        );
        data.umb_schet = response.idOrder;
      } catch (e) {
        catchError(e);
      }
      return api.createBill(data);
    },

    async createOrder({
      commit, state, rootState, dispatch,
    }, cashless = null) {
      commit('setShowOrderPreloader', true);
      try {
        dispatch('getUserDiscount', state.email);

        const response = await api.createOrder(
          rootState.searchBarObjects,
          rootState.cities,
          state.email,
          rootState.exactMatchSearch,
          cashless,
        );
        commit('setOrderId', response.orderNumber);
        commit('setClientId', response.customerNumber);
      } catch (e) {
        catchError(e);
      }
      commit('setShowOrderPreloader', false);
    },
  },
};
