<template>
  <div
    class="eb-button-prime small-button"
    @click="searchAndScroll"
  >
    Поиск
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SearchButton',

  data() {
    return {
      companyListAndBasketElement: {},
    };
  },

  mounted() {
    this.companyListAndBasketElement = document.getElementById('companyListAndBasket');
  },

  methods: {
    ...mapActions(['searchCompany']),

    searchAndScroll() {
      this.searchCompany();
      window.scrollTo({
        top: this.companyListAndBasketElement.getBoundingClientRect().top + window.pageYOffset - 70,
        behavior: 'smooth',
      });
    },
  },
};
</script>
