<template>
  <div class="key_search__faq_button">
    <img
      src="./img/openFaq.svg"
      width="16"
      height="16"
      alt=""
    >
    <span style="white-space: nowrap">Показать пример</span>
  </div>
</template>

<script>
export default {
  name: 'FAQButton',
};
</script>

<style scoped>
.key_search__faq_button {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 5px;
  text-decoration: underline;
  color: var(--color-green-prime, #839F22);
  cursor: pointer;
  font-size: 16px;
}
</style>
