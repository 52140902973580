<template>
  <div class="searchBar">
    <div>
      <div
        v-for="(searchBarObject, index) in searchBarObjects"
        :key="index + '_search_bar_ID'"
        class="search_bar_row"
      >
        <div class="bar">
          <input
            type="text"
            placeholder="Введите ключевое слово или ИНН"
            maxlength="100"
            :value="searchBarObject.search"
            class="text"
            @input="handleInput($event.target.value, index)"
            @keyup.enter="searchCompany"
          >
          <div class="searchOrStop-wrapper">
            <select
              class="searchOrStop-select"
              :class="[searchBarObject.action === 'search' ? '' : 'stopClass']"
              @change="setAction([$event.target.value, index])"
            >
              <option
                value=""
                disabled
              >
                Выберите один из вариантов
              </option>
              <option
                value="search"
                :selected="searchBarObject.action === 'search'"
              >
                Ищем
              </option>
              <option
                value="stop"
                :selected="searchBarObject.action === 'stop'"
                @click="tryAddSearchBar"
              >
                Исключаем
              </option>
            </select>
            <svg
              class="searchOrStop-arrow"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 10"
            >
              <path
                d="M8.8544 9.99999C8.70026 10.0007 8.54754 9.97065 8.40518 9.91156C8.26283 9.85247 8.13371 9.76554 8.0254 9.65587L0.204629 1.83511C0.0486439 1.6069 -0.0217464 1.33094 0.00587241 1.0559C0.0334913 0.780865 0.157346 0.524413 0.355591 0.331778C0.553836 0.139144 0.813737 0.0227016 1.08946 0.00298839C1.36517 -0.0167248 1.639 0.0615578 1.86263 0.224029L8.8544 7.16887L15.8462 0.224029C16.0666 0.0810798 16.3287 0.0162909 16.5904 0.0400465C16.8521 0.0638021 17.0982 0.174727 17.2894 0.355041C17.4805 0.535356 17.6056 0.774618 17.6445 1.03448C17.6835 1.29434 17.634 1.55976 17.5042 1.78818L9.6834 9.60895C9.57912 9.72714 9.45181 9.82278 9.30925 9.89002C9.1667 9.95726 9.01192 9.99468 8.8544 9.99999Z"
                fill="white"
              />
            </svg>
          </div>
          <img
            v-if="(index === 0)"
            src="./img/plusIcon.svg"
            width="32"
            height="32"
            alt="Добавить поле (до 6 штук)"
            title="Добавить поле"
            class="addButton"
            :class="{'disabled': !allowAddSearchBar}"
            @click="tryAddSearchBar"
          >

          <img
            v-else
            src="./img/deleteButton.svg"
            width="32"
            height="32"
            alt="удалить"
            title="Удалить поле"
            class="deleteButton"
            @click="deleteSearchBar(index)"
          >
        </div>
      </div>

      <Hints />

      <div class="extra">
        <SearchButton />
        <DeleteAllButton
          v-if="allowDeleteAllSearchBars"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import Hints from '@/key_search/KeySearchConfigurator/components/Hints/Hints.vue';
import SearchButton from '../SearchButton/SearchButton.vue';
import DeleteAllButton from '../deleteAllButton/DeleteAllButton.vue';

export default {
  name: 'SearchBar',

  components: {
    Hints,
    SearchButton,
    DeleteAllButton,
  },

  computed: {
    ...mapState(['searchBarObjects', 'cities']),

    allowDeleteAllSearchBars() {
      return this.searchBarObjects.length > 1;
    },

    allowAddSearchBar() {
      return this.searchBarObjects.length < 15;
    },
  },

  mounted() {
    if (window.location.hash.match(/query=/)) {
      const string = decodeURI(window.location.hash);
      const query = string.slice(string.indexOf('query=') + 'query='.length);
      this.setSearchString([query, 0]);
      this.searchCompany();
    }
  },

  methods: {
    ...mapMutations([
      'setSearchString',
      'addSearchBar',
    ]),

    ...mapActions([
      'searchCompany',
      'setAction',
      'deleteSearchBar',
    ]),

    handleInput(value, index) {
      this.setSearchString([value, index]);
    },

    tryAddSearchBar() {
      if (!this.allowAddSearchBar) {
        return;
      }
      this.addSearchBar();
    },
  },
};
</script>

<style scoped src="../../css/reset.css" />

<style scoped src="../../../../assets/css/element.css" />

<style scoped lang="less">
.searchBar {
  margin: 0 0 0 10px;
  width: 97.5%;
}

.bar {
  display: flex;
  height: 35px;
  gap: 15px;
}

.search_bar_row {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.searchOrStop-wrapper {
  display: inline-block;
  position: relative;
  width: 205px;
  height: 32px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}

.searchOrStop-select {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  padding: 0 40px 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  appearance: none;
  background: linear-gradient(to right, #fff 78%, #839F22 78%);
  background-color: #fff;
  position: relative;
  transition: background-color 0.3s;
}

.searchOrStop-arrow {
  position: absolute;
  top: 50%;
  right: 6.5%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  pointer-events: none;
}

.searchOrStop-select:hover {
  background: linear-gradient(to right, #fff 78%, #91ad2c 78%);
}

.searchOrStop-select:focus {
  border: 1px solid #839F22;
}

.searchOrStop-select option[value=""]:disabled {
  color: #999;
}

input.text {
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  color: #333;
  font-size: 16px;
  height: 32px;
  flex-shrink: 0;
  width: 70%;
  box-sizing: border-box;
}

input.text:focus {
  border: 1px solid #839F22;
}

input.text::placeholder {
  color: #8c8c8c;
  opacity: 1;
}

input.text:-ms-input-placeholder {
  color: #8c8c8c;
}

input.text::-ms-input-placeholder {
  color: #8c8c8c;
}

select.searchOrStop option {
  color: black;
}

.deleteButton {
  align-self: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.extra {
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
  margin: 25px 0 0 0;
  align-items: center;
}

.addButton {
  align-self: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.addButton.disabled {
  visibility: hidden;
  cursor: default;
}
</style>
