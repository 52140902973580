class CompanyDataStylist {
  constructor() {
    this.styled = false;
  }

  resetStatus() {
    this.styled = false;
  }

  /**
   * @returns {boolean}
   */
  isStyled() {
    return this.styled;
  }

  addSuccessStyled() {
    this.styled = this.styled || true;
  }

  /**
   * Если находится искомое, то возвращается строка с тэгом для подсветки,
   * иначе - изначальная строка.
   * @param text
   * @param searchWord
   * @param searchPartialWord
   * @returns {string|*}
   */
  highlightSearchWord(text, searchWord, searchPartialWord) {
    try {
      let index;
      const indexFullyWord = this.textIncludesSearchWord(text, searchWord);
      const indexPartialWord = this.textIncludesSearchWord(text, searchPartialWord);

      if (indexFullyWord === indexPartialWord){
          index = indexFullyWord;
      } else {
        index = indexPartialWord;
        searchWord = searchPartialWord;
      }

      if (index === -1) {
        return text;
      }

      this.addSuccessStyled();

      let resultText = text.substring(0, index);
      resultText += `<span style="display: inline-block; background-color: #dae3bb; color: #000; padding: 3px 4px 4px;"><span>` +
        `${
        text.substring(index, index + searchWord.length)
      }</span></span>`;
      resultText += text.substring(index + searchWord.length);

      return resultText;
    } catch(e) {
      return '';
    }
  }

  /**
   * Если находится искомое, то возвращается строка,
   * в которой до искомого присутствует не более symbolLimit + 3 символов.
   * Иначе - изначальная строка.
   * @param text
   * @param searchWord
   * @param symbolLimit
   * @returns {string|*}
   */
  squeezeTextBeforeSearchWord(text, searchWord, symbolLimit) {
    try {
      const index = this.textIncludesSearchWord(text, searchWord);

      if (index === -1) {
        return text;
      }

      this.addSuccessStyled();

      return this._squeezeTextBefore(text.substring(0, index), symbolLimit)
        + text.substring(index);
    } catch(e) {
      return '';
    }
  }

  _squeezeTextBefore(text, symbolLimit) {
    if (text.length <= symbolLimit) {
      return text;
    } else {
      return `...${text.substring(text.length - symbolLimit)}`;
    }
  }

  textIncludesSearchWord(text, searchWord) {
    if (typeof text !== 'string' || typeof searchWord !== 'string') {
      throw new Error();
    }
    const lowCaseText = text.toLowerCase();
    const lowCaseSearchWord = searchWord.toLowerCase();
    return lowCaseText.indexOf(lowCaseSearchWord);
  }
}

export default CompanyDataStylist;
